
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import axios from "axios";
// import "../workspace/Work.css";
import "./profile.css";
import Cookies from "js-cookie";

export function Keyfactors({ item: initiaitem, session }) {
  const [item,setItem]=useState(initiaitem?initiaitem.split(","):["Clave 1"," Clave 2", "Clave 3"])  
  const [verify,setVerify]=useState(initiaitem?"ok":"notok")  
    console.log(session)
    
    useEffect(() => {
      setItem(initiaitem?initiaitem.split(",").slice(0):["Clave 1"," Clave 2", "Clave 3"]);
      setVerify(initiaitem?"ok":"notok")
      }, [initiaitem]);
    
      

      const [position,setPosition]=useState("")

    const[editkey,setEditkey]=useState(false)
    const eKey = (indx) => {
    
      setEditkey(true);
      setPosition(indx)

      // setPosition(position_)
      // setType(type_)
      // setAction(action_)
      // setAgregarpos(false)
      // setAgregaruni(false)
      // setAgregardiplo(false)
      // setAgregarcurso(false)
      // setName("")
      // setInstitution("")
      // setYr("")
  
    };






      
    

      const editItemAtPosition = (newItem, position) => {
        setItem(prevItems => {
          // Crear una copia del arreglo
          const updatedItems = [...prevItems];
          // Editar el elemento en la posición especificada
          updatedItems[position] = newItem;
          return updatedItems;
        });
      };
      const saveData =() =>{
        setEditkey(false);
        update_info({key_items: item.join(",")},setItem,item)
      }

      const update_info = (info, setData, data) => {
        axios
          .post("/api/update_user_info", info,
            {
              withCredentials: true,
              headers: { 'X-CSRF-TOKEN': Cookies.get('csrf_access_token') },
          })
          .then((response) => {
            console.log(response);
            setData(data);
  
          })
          .catch((error) => console.log(error));
      }
 

        // eslint-disable-next-line no-unused-vars
        const Lista_keypoints =["Escribe una opción","Espacio Profesional","Espacio Seguro","Experiencia y especialización","Espacio seguro LGBTi+","Terapia basada en la evidencia","Terapias complementarias",
          "Flores de Bach","Enfoque DDHH","Jungiano","Profesional Neurodivergente", "Espacio seguro","Terapia Familiar",
          "Infancia Respuetuosa","Test psicológicos",
          "Atención a Extranjeros","Terapia de Parejas","Atención a pueblos originario","Espacio Cristiano","Espacio Católico",
        "Espacio feminista","Reiki","Mindfulness"]

                            
    return(
     <div className={`card-text schedu ${verify === "notok" && session === false ? "notshow" : "show"}`}>


        <h5 className="card-title pref mt-2 redh5" style={{ textAlign: "center" }}>Claves de mi espacio terapéutico</h5>
        <p style={{ fontSize: "13px", textAlign: "left", display: session ? "block" : "none" }}>
          Acá podrás poner los puntos claves sobre tu espacio, será lo primero que verá la gente al ingresar tu perfil.  </p>
          <p style={{ fontSize: "13px", textAlign: "left", display: session ? "block" : "none" }}>
          Si no actualizas la información, esta sección no se mostrará públicamente.  </p>


        <div className="variable-container3 mt-3">
          {Object.keys(item).map((indx) => (
            <div key={indx} style={{ textAlign: "center", margin:"0 auto"}}>
              <p>
                
                <p style={{ display: editkey === true && position === indx ? "none" : "block" , textAlign:"center", margin:"0 auto",alignItems:"center",alignSelf:"center", verticalAlign:"middle"}}>
                  <span style={{display:"inline-block"}}><i className="fa-solid fa-circle"style={{fontSize:"8px", color:"#ee6c4d",verticalAlign:"middle",alignSelf:"center",marginRight:"5px" }}></i> </span>{item[indx]}
              <span>
                <i className={`fa-solid fa-pen ${session === true  ? "show" : "notshow"}`}
                  onClick={() => { eKey(indx) } } style={{ margin: "10px" }}></i>
              </span></p>

              </p>
              <div className={`${item[indx] === "Escribe una opción" && position === indx ? "notshow" : "show"}`} style={{ justifyItems: "center", textAlign: "center", margin: "0 auto", display: "flex" }}>
                <select value={item[indx]}
                className={`${item[indx] === "Escribe una opción" && position === indx ? "notshow" : "show"}`}
                style={{ justifySelf: "center", margin: "0 auto", alignSelf: "center", display: editkey === true && position === indx ? "block" : "none" }}
                onChange={(e) => editItemAtPosition(e.target.value, indx)}>
                {Lista_keypoints.map((valor, index) => (
                  <option key={index} value={valor}>
                    {valor}
                  </option>
                ))}

              </select>

              </div>
              <p className={`${item[indx] === "Escribe una opción" || !(Lista_keypoints.includes(item[indx])) && position === indx && editkey===true ? "show" : "notshow"}`}> 
               
                <input
                required
                type="text"
                className={`inputacademicnae`}
                value={item[indx]}
                onChange={(e) => editItemAtPosition(e.target.value, indx)}
                placeholder={"Escribe una opción"} 
                maxLength="25" />
                       </p> 
            </div>

          ))}

          <div style={{ textAlign: "right" }}><button className={`button-login profilebutton ${session === true && editkey === true ? "show" : "notshow"}`} onClick={saveData} disabled={item.includes("Escribe una opción") || item.includes("Clave 1") || item.includes("Clave 2") || item.includes("Clave 3")}>

            Guardar
          </button></div>

        </div>
 <p style={{ fontSize: "13px", textAlign: "center", display: session ? "block" : "none" }}>
         Puedes escribir lo que gustes o seleccionar una de las opciones disponibles.  </p>
     

      </div>


           );
}
Keyfactors.propTypes = {
    item: PropTypes.string,
    session: PropTypes.bool,
  };