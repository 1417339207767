import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Image from "./img/networking.jpeg";
// import Image from "./img/448ab3.png";
import NavBar from "./navbar/navbar";
import Contact from "./contact/Contact";
import Fotr from "./Footer/footer";
import { UserContext } from "./login/login-context";

const Home = () => {
  const [isExpanded, setisExpanded] = useState(false);

  const expandir = () => {
    setisExpanded(true);
  };
  const [txt, setTXT] = useState("");
  const [respuestaT, setRespuestaT] = useState({});

  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const [ user, setUser ] = useContext(UserContext);
  useEffect(() => {
    if (user && user.logged_in) {
      // navigate("/home" + user.profile_link);
      navigate("/home");
    }
  }, [user]);

  const enviarFormulario = () => {
    // Datos a enviar en la solicitud POST
    const formData = {
      txt: txt,
    };
    axios
      .post("/api/process", formData)
      .then((response) => {
        // Manejar la respuesta exitosa aquí
        console.log("Respuesta:", response.data);
        setRespuestaT(response.data);
      })
      .catch((error) => {
        // Manejar errores aquí
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (Object.keys(respuestaT) != 0) {
      navigate("/psicologos", { state: { query: respuestaT } });
    }
  }, [respuestaT]);

  const params = useParams();
  const openModalProps = {loginModalOpen: params.type == 'login' ? true : false};

  return (
    <div>
      <NavBar {...openModalProps} />

      <section className={`elemento ${isExpanded ? "expandedmobile" : ""}`}>
        <div className="centerin">
          {/* <h1 className="fuente1">¡Todo empieza con un buen Match! </h1> */}
          <h1 className="fuente1" style={{ marginTop: "4%" }}>
            {/* Porque un buen match es lo más importante */}
            Todo empieza con un buen
          </h1>
          <div className="subsection">
            <div className="div-izquierdo ">
              <p className="fuente2">
                {/* Buscaremos por ti profesionales que se ajusten a tus
                preferencias y necesidades. Para empezar, cuéntanos. */}
                Te ayudamos a encontrar un psicólogo o psicóloga ideal para tu
                proceso.   Solo necesitamos que nos cuentes:  
              </p>
              <p className="fuente2">
                {/* Buscaremos por ti profesionales que se ajusten a tus
                preferencias y necesidades. Para empezar, cuéntanos. */}
              
              </p>
              {/* <p>1.- ¿Por qué estás buscando terapia?</p>
              <p>2.- Algo que nos puedas comentar sobre ti.</p>
              <p>3.- ¿Qué buscas o esperas de tu terapeuta?</p> */}

              <article className="artichome">
                <h2
                  style={{ textAlign: "center", margin: "3% 0% 1% 0%" }}
                  className="fuente2"
                >
                  ¿Por qué estás buscando terapia?
                </h2>
                <textarea
                  id="mensaje"
                  name="mensaje"
                  className={`caja-texto ${isExpanded ? "cajaexpanded" : ""}`}
                  placeholder="...Acá te leemos..."
                  value={txt}
                  onChange={(e) => setTXT(e.target.value)}
                  onFocus={expandir}
                ></textarea>
                {/* <div className="fuente2">{respuestaT}</div> */}
                <div className="divboton">
                  <a href="#">
                    <button
                      className="boton-ovalado"
                      onClick={enviarFormulario}
                      disabled={txt.length < 1}
                    >
                      <b>Encuentra</b>{" "}
                      <i className="fa-solid fa-magnifying-glass"></i>{" "}
                    </button>
                  </a>
                </div>
                <p
                  className="message"
                  style={{ marginTop: "25px", textAlign: "center" }}
                >
                  <a
                    href="/ayuda"
                    className="boton-enlace"
                    style={{ textShadow: "2px 2px 4px #a0a0a0" }}
                  >
                    <b>Ayúdame a encontrar</b>{" "}
                    <i className="fa-solid fa-circle-question"></i>
                  </a>
                </p>
              </article>
              {/* <CommentBox /> */}
            </div>

            <div className="div-derecho ">
              <img src={Image} className="imglnd" alt="red de psicologos" />
            </div>
          </div>
          <div>
      {/* <iframe
        title="google map"
         src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.9915177611983!2d-70.61073392359138!3d-33.42346549613178!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cfc8869cc193%3A0x21249a7338ca3283!2sEntrevinculos.cl%20%7C%20Psicoterapia%20de%20Pareja%2C%20Familia%2C%20Infanto-juvenil%20y%20Adultos!5e0!3m2!1ses!2scl!4v1723915512770!5m2!1ses!2scl"
        width="20%"
        height="200px"
        style={{ border: 0 }}
        allowfullscreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe> */}
    </div>
          <div className="container"></div>
        </div>
      </section>
      <Contact />
      <Fotr />
    </div>
  );
};
export default Home;
