import React, { useState, useRef ,useEffect} from "react";
import "./NavBar.css"; // Asegúrate de tener tu archivo de estilos CSS
import "../../App.css";
import Cookies from "js-cookie";
// import Logo from "../img/vinculando.png";
import Logo from "../img/LogoVincularse.png";
import Modal_ from "../Modals/Modal_";
import Login from "../login/Login";
import RegistrationForm from "../registration/Form";



// eslint-disable-next-line react/prop-types
export function NavBarSup (
  // eslint-disable-next-line react/prop-types
  {profile:initial_profile}
) {
  const registrationRef = useRef();

  const [profile,setProfile]=useState(initial_profile)
  useEffect(() => {
    setProfile(initial_profile);
  }, [initial_profile ]);
  const userSession = Cookies.get('logged_in');


  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isRegistrationModalOpen, setIsRegistrationinModalOpen] =
    useState(false);

  const openLoginModal = () => {
    setIsLoginModalOpen(true);
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
  };

  // eslint-disable-next-line no-unused-vars
  const openRegistrationModal = () => {
    setIsRegistrationinModalOpen(true);
  };

  const closeRegistrationModal = () => {
    setIsRegistrationinModalOpen(false);
  };

  const switchToRegistration = () => {
    closeLoginModal();
    registrationRef.current.click();
  };
  const logOut = () => {    
    
    Cookies.remove('logged_in');
    Cookies.remove('user');
    Cookies.remove('csrf_access_token');
    Cookies.remove('token');
    // Redirige al usuario a la página de inicio
    window.location.href = '/';
        
        
  };
  
console.log(profile+"este es profiel")
  return (
    <nav className="navbar_profiles">
      <div className="navbar-brand_profiles">
        <a href="/">
          <img
            className="imghead_profiles"
            href="/"
            src={Logo}
            alt="Descripción de la imagen"
          />
        </a>
      </div>


<div>
      {/* Mostrar la lista de navegación en pantallas grandes */}
      <a className={`${profile === "true" && userSession == "true"  ? "show" : "notshow"}`}
       
                href="/home"
            
                style={{fontSize:"11px",margin:"5px"}}
              >
               Home
              </a>
   
        <a className={`${userSession == "true" ? "notshow" : "show"}`}
                onClick={() => {openLoginModal()}}
                href="#"
                data-toggle="modal"
                data-target="#exampleModal"
                style={{fontSize:"11px",margin:"5px"}}
              >
                Iniciar sesión
              </a>
              <a className={`${userSession == "true" ? "show" : "notshow"}`}
                onClick={() => {logOut()}}
                style={{fontSize:"11px"}}
               
              >
                Cerrar sesión
              </a>
              </div>
      <Modal_
        isOpen={isLoginModalOpen}
        id="exampleModal"
        onClose={closeLoginModal}
      >
        <Login switchModal={switchToRegistration} />
      </Modal_>
      <Modal_
        isOpen={isRegistrationModalOpen}
        id="registrationModal"
        onClose={closeRegistrationModal}
      >
        <RegistrationForm />
      </Modal_>
    </nav>
  );
}
