import Puntos from "../img/puntos.png";
import Nophoto  from "../img/nonprof.webp";
export const Card = (photo, name, title, university, href, items) => {
  var listItems;
  if (items != undefined) {
    listItems = items.map((item) => (
      <div
        key={item}
        className="rounded-pill px-2 py-1   catalog__espprof"
      >
        {item}
      </div>
    ));
  }

  return (
    <div className="col mt-4 psico-catalog">
      <div className="card catalog__card2" style={{ width: "100%" }}>
        <div className="imgframe2 imgcatalog">
          <img className="dots-us-catalog" src={Puntos} />
          <img
            className="img-circular-us-catalog"
            src={photo? photo:Nophoto}
            alt="Descripción de la imagen"
          />
        </div>
        <div className="card-body catalog-text">
          <div>
            <h5 className="lfh catalog__lfh">{name}</h5>
          </div>
          <p>Atención: {title}</p>
          
          <p>{university}</p>
          {listItems ? <div className="catalog__profile_esp">{listItems}</div> : <></>}
          <a className="linkcard" href={"ps/" + href}>
            <p style={{fontSize:"13px"}}>Ver Perfil</p>
          </a>
        </div>
      </div>
    </div>
  );
};
