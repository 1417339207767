import { createContext, useEffect, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Cookies from "js-cookie";

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
    UserProvider.propTypes = {
        children: PropTypes.object
    }
    const [user, setUser] = useState(null);

    useEffect(() => {
        const token = Cookies.get("logged_in");
        if (token && token != undefined) {
            axios
                .get("/api/validate_token", { withCredentials: true })
                .then((response) => {
                    setUser({
                        logged_in: true,
                        guid: response.data.guid,
                        profile_link: response.data.profile_link
                    });
                })
                .catch((error) => {
                    console.log("error validate", error);
                    Cookies.set("logged_in", false), { secure: true };
                    setUser({ logged_in: false });
                });
        }
    }, []);

    return (
        <UserContext.Provider value={[user, setUser]}>
            { children }
        </UserContext.Provider>
    );
}
