/* eslint-disable react/prop-types */

import React, { useState, useEffect } from "react";
import Modal_ from "../Modals/Modal_";
import { useParams } from "react-router-dom";
import { EditarBasicinfo } from "./Editarbasic.js";
// import "../workspace/Work.css";
import "./profile.css";
import axios from "axios";
// import "../workspace/Work.css";
import "./profile.css";

import Nophoto from "../img/nonprof.webp";



export function Basicprofile({
  user,
  titulo,
  nombre,
  apellido,
  genero,
  exp,
  minsal,
  enfoque,
  nacionalidad,
  img,
  session,
}) {
  // const [isExpanded_data, setisExpanded_data] = useState(false);

  // const expandir_data = () => {
  //   setisExpanded_data(!isExpanded_data);
  // };
  const [isBasicModalOpen, setIsBasicModalOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true); // Estado de carga
  const [university, setUniversity] = useState([{}]);
  const enfoque_array = enfoque ? enfoque.split(",").slice(0) : [];
  const titulo_array = titulo ? titulo.split(",").slice(0) : ["Adulto"];
  const closeBasicModal = () => {
    setIsBasicModalOpen(true);
    false;
  };
  const openBasicModal = () => {
    setIsBasicModalOpen(true);
  };
  const [photo, setPhoto] = useState({});

  const { user_id } = useParams();

  useEffect(() => {
    if (!img) {
      setPhoto(Nophoto);
    } else {
      setPhoto(img);
    }
  }, [img, Nophoto]);
  useEffect(() => {
    setUniversity(university)
  }, [university]);
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/academicfeeder/${user_id}`);

        setUniversity(response.data[1]);

        console.log("Respuesta selection:", response.data);
      } catch (error) {
        console.error("Error al cargar los datos:", error);
      } finally {
        setLoading(false); // Finaliza la carga
      }
    };
    fetchData();
  }, []);

  return (
    <div className="izq expanded2" style={{ border: "0px" }}>
      <div className="card-body ">
        <p className="profesion1">
          {genero == "Masculino"
            ? "Psicólogo"
            : genero == "Femenino"
              ? "Psicóloga"
              : "Psicólogue"}{" "} de
        </p>

        <p className="profesion2">{" " + titulo_array.join(", ")}</p>
        <div className="infoprof">
          <p className="card-text landprf">
            {/* <p className="barra-color"></p> */}
            <div className="imgframe">
              
              <img
                className="img-circular-us-p"
                src={img ? photo : Nophoto}
                alt="Descripción de la imagen"
              />
            </div>
            <h5 className="card-title lfh mt-2" style={{ display: "flex" }}>
                           <span className="namedesktop">{nombre + " "+ apellido}</span>

             <span className="namemobile">{nombre && apellido && (  (nombre.length + apellido.length >25) ?( (nombre.length>12? nombre.substr(0,10)+"...":nombre)+" "+(apellido.length>12? apellido.substr(0,10)+"...":apellido)): (nombre +" " + apellido))}</span>
            <span className="namemobile2">{nombre && apellido && (  (nombre.length + apellido.length >21) ?( (nombre.length>10? nombre.substr(0,9)+"...":nombre)+" "+(apellido.length>11? apellido.substr(0,8)+"...":apellido)): (nombre +" " + apellido))}</span>

              <p style={{ margin: "0%" }}>
                <a
                  onClick={() => {
                    openBasicModal();
                  }}
                  href="#"
                  data-toggle="modal"
                  data-target="#editarperfilmodal"
                >
                  <i
                    className={`fa-solid fa-pen lapizperfil ${session ? "show" : "notshow"}`}
                    style={{ fontSize: "14px", height: "100%" }}
                  ></i>
                </a>

                {/* <a
                  className="expandir"
                  style={{
                    marginLeft: "60px",
                    position: "relative",
                    bottom: "60%",
                    color: "rgb(142, 142, 142)",
                  }}
                  onClick={expandir_data}
                >
                  <i
                    className={`fa-solid fa-caret-${isExpanded_data ? "up" : "down"}`}
                    style={{ margin: "2%", fontSize: "16px" }}
                  ></i>
                </a> */}
              </p>
            </h5>{" "}
          </p>
          <div className="infomobile2">
            <div style={{ display: "block" }}>
              <p className="perfilp" style={{ display: "flex" }}>
                <span>Enfoque: </span>
                <span>{" " + enfoque_array.join(", ")}</span>
              </p>
              <p className="perfilp">Experiencia:{exp ? exp : "0"} años.</p>
              <p className="perfilp">Género:{genero}</p>
              <p className="perfilp">
                <a href="https://rnpi.superdesalud.gob.cl/#">
                  <i className="fa-solid fa-link"></i> Registro Minsal:
                </a>{" "}
                {minsal}{" "}
              </p>

              <p className="perfilp notshowmobile">
                {" "}
                Nacionalidad: {nacionalidad}
              </p>
              <p className="perfilp notshowmobile">
                {" "}
                Universidad: {university.length>0?university[0]["academic_institution"]:""}{" "}
              </p>
            </div>
          </div>
        </div>
        <p
          className="card-text"
          style={{ display: "inline-flex", justifyContent: "space-between" }}
        >
          {/* <div className="infolaptop" >

            <p style={{display:"flex"}}>
          <p className="perfilp" style={{width:"40%"}}>Enfoque: </p><p className="perfilp" >{enfoque}.</p>
          </p>

          <p style={{display:"flex"}}>

          <p className="perfilp" style={{width:"50%"}}>Experiencia:</p><p className="perfilp"> {exp} años.</p>

          </p>
          
          <p style={{display:"flex"}}>
          <p className="perfilp" style={{width:"40%"}}>Género: </p><p className="perfilp">{genero}.</p>
          </p>

          <p style={{display:"flex"}}>

          <p className="perfilp" style={{width:"50%"}}>Registro Minsal : </p><p className="perfilp">{minsal}.</p>
          </p>

          <p style={{display:"flex"}}>
          <p className="perfilp" style={{width:"50%"}}>Nacionalidad: </p><p className="perfilp">{nacionalidad}.</p>
          </p>
          <p style={{display:"flex"}}>
          <p className="perfilp" style={{width:"50%"}}>Estudios: </p><p className="perfilp">{universidad}.</p>
          </p> */}
          <div className="infolaptop">
            <div style={{ display: "block" }}>
              <p className="perfilp">
                Enfoque: {" " + enfoque_array.join(", ")}
              </p>
              <p className="perfilp">Experiencia:{exp ? exp : "0 "} años.</p>
              <p className="perfilp">Género:{genero}</p>
              <p className="perfilp">
                <a
                  style={{ color: "white" }}
                  href="https://rnpi.superdesalud.gob.cl/#"
                >
                  <i className="fa-solid fa-link"></i> Registro Minsal:
                </a>{" "}
                {minsal}
              </p>
              <p className="perfilp"> Nacionalidad: {nacionalidad}</p>
              <p className="perfilp">
                {" "}
                Universidad: {university.length>0?university[0]["academic_institution"]:""}
              </p>
            </div>
          </div>
          {/* <div className="infomobile" >
          <div style={{display:"block"}}>

          <p className="perfilp" style={{display:"flex"}}><span >Enfoque: </span><span>{enfoque}</span></p>

          <p className="perfilp">Experiencia:{exp?exp:"0 "} años.</p>
          <p className="perfilp">Género:{genero}</p>
          <p className="perfilp">Superintendencia de Salud : {minsal}</p>
          <p className="perfilp"> Nacionalidad: {nacionalidad}</p>
          <p className="perfilp"> Universidad: {universidad} </p>
          </div>

          </div> */}
        </p>
        {/* <p className="card-text" style={{display:"inline-flex", justifyContent:"space-between"}}>
          <div style={{display:"flex"}}>
          <div style={{display:"block"}}>
          <p className="perfilp" >Enfoque:</p>
          <p className="perfilp">Experiencia: </p>
          <p className="perfilp">Género:</p>
          <p className="perfilp">Superintendencia de Salud : </p>
          <p className="perfilp"> Nacionalidad: </p>
          <p className="perfilp"> Universidad: </p>
          </div>
          <div style={{display:"block"}}>
          <p className="perfilp" >{enfoque}.</p>
          <p className="perfilp">{exp} años.</p>
          <p className="perfilp">{genero}</p>
          <p className="perfilp">{minsal}</p>
          <p className="perfilp">  {nacionalidad}</p>
          <p className="perfilp"> {universidad}</p>
          </div>
          </div>
        </p> */}
      </div>
      <Modal_
        isOpen={isBasicModalOpen}
        id="editarperfilmodal"
        onClose={closeBasicModal}
      >
        <EditarBasicinfo
          user={user}
          edad={titulo}
          enfoque={enfoque}
          genero={genero}
          experiencia={exp}
          minsal={minsal}          
          nacionalidad={nacionalidad}
          imgprof={img}
        />
      </Modal_>
    </div>
  );
}
