import React, { useState } from "react";
// import "../workspace/Work.css";
import "./onlinepresencial.css";
import "./profile.css";
// import Weekly from "../agenda-calendario/Calendarioweek";
import {Services} from "./services"

// eslint-disable-next-line react/prop-types
export function Onlinepresencial({ Nombre, apellido, session }) {
  // eslint-disable-next-line no-unused-vars
  const [isOnline, setIsOnline] = useState(true);
  // eslint-disable-next-line no-unused-vars
  // const [isOffline, setIsOffline] = useState(false);

  // const handleOnlineClick = () => {
  //   setIsOnline(true);
  //   setIsOffline(false);
  // };

  // const handleOfflineClick = () => {
  //   setIsOnline(false);
  //   setIsOffline(true);
  // };

  return (
 
      <article className="onlinepresencial">
          
          <h5 className="title-sectionprofile pref redh5"> Prestaciones</h5>
          <div className="service-title">

        {/* <div
          className="btn-group btn-group-toggle butonlinepres"
          data-toggle="buttons"
        >
          <label className="btn btn-secondary profi active">
            <input
              type="radio"
              name="options"
              id="option1"
              autoComplete="off"
              defaultChecked
              onClick={handleOnlineClick}
            />{" "}
            Online 
          </label>
          <label className="btn btn-secondary profi">
            <input
              type="radio"
              name="options"
              id="option2"
              autoComplete="off"
              onClick={handleOfflineClick}
            />{" "}
            Presencial
          </label>
        </div> */}
        </div>
        <article className="calendarcontainer">
          <Services session={session } modality={isOnline} nombre={Nombre} apellido={apellido}/>
        </article>
        {/* <article className="subcostos">

          <p>
            <p className={`${isOnline ? "showedvalor" : "notshowed"}`}>
            {valoronline!= "none"? "Valor atención :": "Lo sentimos, actualmente "+ Nombre +" no está atendiendo de forma online."}
            </p>
            <p className={`${isOffline ? "showedvalor" : "notshowed"}`}>
            {valorpresencial!= "none"? "Valor atención :": "Lo sentimos, actualmente "+ Nombre +" no está atendiendo de forma presencial."}
            </p>
          </p>
          <p>
            <p className={`${isOnline ? "showedvalor" : "notshowed"}`}>
              {valoronline!= "none"? "$" + valoronline:""}
            </p>
            <p className={`${isOffline ? "showedvalor" : "notshowed"}`}>
            {valorpresencial!= "none"? "$" + valorpresencial:""}
            </p>
          </p>
        </article> */}
     
        {/* <article className="calendarcontainer">
          <Weekly isOnline={isOnline} />
        </article> */}
        
            
      </article>
      

  );
}



